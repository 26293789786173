<template>
  <v-row>
    <v-col cols="12">
      <div><strong>{{tipoComunicacaoSelecionado}}</strong> - <strong>{{qtdClientesSelecionados}}</strong></div>
      <v-divider/>
    </v-col>
    <v-col cols="12" md="12">
      <v-text-field 
          label="Informe um assunto para essa(s) mensagem(ns)"
          dense
          value=""
          v-model.trim="value.descricao"
          :rules="[
                      this.$validators.string.required
                  ]"/>
    </v-col>
    <!-- <v-col cols="12" md="4">
      <lookup-dominio label="Enviar por"
                      v-model="value.tipoEnvioMensagem"
                      :type="lookups.tipoEnvioMensagem"
                      :hidden-clear="true"
                      :rules="[
                          this.$validators.string.required
                      ]"/>
    </v-col> -->
    <v-col cols="12" sm="4">
      <v-radio-group dense v-model="value.vaiEnviarImagem" row 
          :rules="[
                this.$validators.string.required,
          ]">
        <v-radio 
          label="Sem anexo"
          value="N"
        ></v-radio>
        <v-radio 
          label="Com anexo"
          value="S"
        ></v-radio>        
      </v-radio-group>
    </v-col>
    <v-col cols="12" md="8" v-show="value.vaiEnviarImagem=='S'">
      <v-file-input
        accept=".jpg, .jpeg, .png, .gif, .mp4"
        label="Escolha uma imagem ou um vídeo de até 20MB"
        v-model="value.caminhoImagem"
        :rules="[
            v => this.$validators.number.tamanhoMaxArquivo(v, 20)
          ]"
        ></v-file-input>
    </v-col>
    <v-col cols="12">
      <v-textarea
          label="Texto da mensagem"
          counter
          filled
          prepend-icon="mdi-comment"
          rows="5"
          v-model="value.mensagem" 
          :rules="[
                      this.$validators.string.required
                  ]"
        ></v-textarea>
    </v-col>
    <v-col cols="12" class="text-caption">
      <v-divider/>
      <div>Ao programar o envio da(s) mensagens(s), você poderá usar as chaves abaixo e o sistema substituirá automaticamente pelo valor correspondente a cada linha marcada</div>
      <ul>
        <li>{nome} - Nome de quem receberá a notificação</li>
        <li>{numContrato} - Número do contrato</li>
      </ul>
      <v-divider/>
    </v-col>
  </v-row>
</template>

<script>
import {ItemDeDominio} from "../../../api/itemsDominio";
import LookupDominio from "../common/LookupDominio";
import SearchBox from "../common/SearchBox";
import Vue from 'vue';

export default {
  name: "MensagemForm",
  components: {SearchBox, LookupDominio},
  props: {
    value: {
      type: Object
    },
    clientesSelecionados: {
      type: Array
    },
    searchValues: {
      type: Object
    }
  },
  data() {
    return {
      lookups: {
        tipoEnvioMensagem: ItemDeDominio.TIPO_ENVIO_MENSAGEM,
      },
      mensagemTipo1: `Prezado(a) {nome},\n\nO contrato de Nº {numContrato}...\n\nAtt,\nequipe Gestão de Contrato.`,
      mensagemTipo2: `Prezado(a) {nome},\n\nFique atendo que o contrato de Nº {numContrato} está próximo de vencer...\n\nAtt,\nequipe Gestão de Contrato.`,
      mensagemTipo3: `Prezado(a) {nome},\n\n...\n\nAtt,\nequipe Gestão de Contrato.`,
    }
  },
  computed: {
    qtdClientesSelecionados() {
      return "" + this.clientesSelecionados.length + " contatos(s) selecionado(s).";
    },
    tipoComunicacaoSelecionado() {
      let desTipoComunicacao = "";
      if (this.searchValues.tipoComunicacao === 'CO') desTipoComunicacao = " por Contrato";
      if (this.searchValues.tipoComunicacao === 'VG') desTipoComunicacao = " Contratos que vencem em até 30 dias";
      if (this.searchValues.tipoComunicacao === 'CT') desTipoComunicacao = " por Contratado";
      return desTipoComunicacao == "" ? "Nenhum Tipo de comunicação selecionado" : "Tipo de comunicação selecionado: " + desTipoComunicacao;
    },
  },
  methods: { 
    getTextoMensagem() {
      if (this.searchValues.tipoComunicacao === 'CO') Vue.set(this.value,'mensagem',this.mensagemTipo1);
      if (this.searchValues.tipoComunicacao === 'VG') Vue.set(this.value,'mensagem',this.mensagemTipo2);
      if (this.searchValues.tipoComunicacao === 'CT') Vue.set(this.value,'mensagem',this.mensagemTipo3);
    },
    setVaiEnviarImagem() {
      Vue.set(this.value,'vaiEnviarImagem','N');
    },
  },
  mounted(){
    this.getTextoMensagem();
  }
}
</script>

<style scoped>

</style>