var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.filteredHeaders,"dense":"","show-select":true,"single-select":false,"item-key":"id","fixed-header":"","options":_vm.pageOptions,"server-items-length":_vm.totalElements,"disable-sort":true,"hide-default-header":_vm.$vuetify.breakpoint.mobile,"footer-props":{
                      disableItemsPerPage: false,
                      itemsPerPageOptions: [_vm.itemsPerPage],
                      },"items":_vm.elements,"loading":_vm.loading},on:{"update:options":function($event){_vm.pageOptions=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-card',{staticClass:"elevation-0"},[_c('v-card-text',{staticClass:"text-center"},[(_vm.elements.length === 0)?_c('v-alert',{attrs:{"border":"bottom","color":"blue-grey","dark":""}},[_vm._v(" Informe pelo menos um item do filtro para selecionar quem receberá a mensagem. ")]):_vm._e()],1)],1)]},proxy:true},{key:"item.id",fn:function(ref){
                      var item = ref.item;
return [_c('strong',[_vm._v("#"+_vm._s(item.id))])]}},(_vm.$vuetify.breakpoint.mobile)?{key:"body",fn:function(ref){
                      var items = ref.items;
                      var isSelected = ref.isSelected;
                      var select = ref.select;
return [_c('v-card',{staticClass:"elevation-0"},[_c('v-card-text',{staticClass:"text-center"},[(items.length === 0)?_c('v-alert',{attrs:{"border":"bottom","color":"blue-grey","dark":""}},[_vm._v(" Informe pelo menos um item do filtro para selecionar quem receberá a mensagem. ")]):_vm._e()],1)],1),_c('v-list',{staticClass:"pa-0",attrs:{"dense":""}},_vm._l((items),function(item){return _c('v-list-item',{key:item.id,staticClass:"list-item"},[_c('v-list-item-avatar',[_c('strong',[_vm._v("#"+_vm._s(item.id))])]),_c('v-list-item-content',[(item.numContrato!=='')?_c('v-list-item-title',[_vm._v(_vm._s(item.numContrato))]):_vm._e(),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.nomePessoa)+" - "+_vm._s(item.emailPessoa))])],1)],1)}),1)]}}:null],null,true),model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }