"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.remove = exports.update = exports.createPorListaContatos = exports.findOne = exports.findAllContatos = exports.findAll = void 0;
const core_1 = require("./core");
function findAll(page = 1, search) {
    return core_1.get('Mensagem', 'lista', Object.assign({ pg: page }, search))
        .then(({ dados, paginacao }) => {
        if (dados.length === 0)
            return {
                elements: [],
                elementsPerPage: 0,
                totalElements: 0,
                currentPage: 0,
                totalPages: 0
            };
        return {
            elements: dados,
            elementsPerPage: paginacao.totalRegPorPagina,
            totalElements: paginacao.totalRegEncontrados,
            currentPage: paginacao.pagAtual,
            totalPages: paginacao.qtdPag
        };
    });
}
exports.findAll = findAll;
function findAllContatos(page = 1, search) {
    return core_1.get('Mensagem', 'listaContatos', Object.assign({ pg: page }, search))
        .then(({ dados, paginacao }) => {
        if (dados.length === 0)
            return {
                elements: [],
                elementsPerPage: 0,
                totalElements: 0,
                currentPage: 0,
                totalPages: 0
            };
        return {
            elements: dados,
            elementsPerPage: paginacao.totalRegPorPagina,
            totalElements: paginacao.totalRegEncontrados,
            currentPage: paginacao.pagAtual,
            totalPages: paginacao.qtdPag
        };
    });
}
exports.findAllContatos = findAllContatos;
function findOne(id) {
    return core_1.get('Mensagem', 'detalhe', { id });
}
exports.findOne = findOne;
function createPorListaContatos(data) {
    return core_1.post('Mensagem', 'cadastroLista', Object.assign({}, data));
}
exports.createPorListaContatos = createPorListaContatos;
function update(id, data) {
    return core_1.put('Mensagem', 'update', Object.assign({ id }, data));
}
exports.update = update;
function remove(id) {
    return core_1.del('Mensagem', 'exclui', { id });
}
exports.remove = remove;
