<template>
  <div>
    <v-data-table :headers="filteredHeaders"
                  dense
                  :show-select="true"
                  :single-select="false"
                  item-key="id"
                  v-model="selection"
                  fixed-header
                  :options.sync="pageOptions"
                  :server-items-length="totalElements"
                  :disable-sort="true"
                  :hide-default-header="$vuetify.breakpoint.mobile"
                  :footer-props="{
                        disableItemsPerPage: false,
                        itemsPerPageOptions: [itemsPerPage],
                        }"
                  :items="elements" :loading="loading">
      <template v-slot:no-data>
        <v-card class="elevation-0">
          <v-card-text class="text-center">
            <v-alert v-if="elements.length === 0" border="bottom" color="blue-grey" dark >
              Informe pelo menos um item do filtro para selecionar quem receberá a mensagem.
            </v-alert>
          </v-card-text>
        </v-card>
      </template>
      <template v-slot:item.id="{item}">
        <strong>#{{item.id}}</strong>
      </template>

      <template v-slot:body="{items, isSelected, select}" v-if="$vuetify.breakpoint.mobile">
        <v-card class="elevation-0">
          <v-card-text class="text-center">
            <v-alert v-if="items.length === 0" border="bottom" color="blue-grey" dark >
              Informe pelo menos um item do filtro para selecionar quem receberá a mensagem.
            </v-alert>
          </v-card-text>
        </v-card>
        <v-list dense class="pa-0">
          <v-list-item v-for="item of items" :key="item.id" class="list-item">
            <v-list-item-avatar>
              <strong>#{{item.id}}</strong>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-if="item.numContrato!==''">{{item.numContrato}}</v-list-item-title>
              <v-list-item-subtitle>{{item.nomePessoa}} - {{item.emailPessoa}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import {findAllContatos} from '../../../api/mensagens';
import {ApiError} from "../../../api/core";

/**
 * @emits click:edit
 * @emit click:remove
 */
export default {
  name: "MensagensDataTable",
  props: {
    searchValues: {
      type: Object
    }
  },
  data() {
    return {
      selection: [],
      selectedItem: null,
      loading: false,
      error: null,
      pageOptions: {
        page: 1
      },
      itemsPerPage: 0,
      totalElements: 0,
      elements: [],
      erroBuscaClientes: '',
      headers: [
        {
          value: 'contador',
          text: 'SEQ',
          align: 'start'
        },          
        {
          value: 'numContrato',
          text: 'Nº Contrato',
          align: 'start',
          width: "10%"
        },
        {
          value: 'descTipoContrato',
          text: 'Tipo Contrato',
          align: 'start',
          width: "20%"
        }, 
        {
          value: 'descModalidade',
          text: 'Modalidade',
          align: 'start'
        },       
        {
          value: 'status',
          text: 'Status',
          align: 'start'
        },
        {
          value: 'nomePessoa',
          text: 'Nome',
          align: 'start'
        },
        {
          value: 'emailPessoa',
          text: 'E-mail',
          align: 'start'
        },
      ]
    }
  },
  computed: {
    selectedItemName() {
      return this.selectedItem?.nome
    },
    filteredHeaders() {
      if (this.searchValues.tipoComunicacao === 'PE') {
        // Se a variável shouldExcludeItems for true, exclua os itens
        return this.headers.filter((item, index) => {
          // Aqui, podemos verificar o índice do item para excluir os itens 2, 3, 4 e 5
          return ![1, 2, 3, 4].includes(index); // 0 index-based
        });
      } else {
        return this.headers;
      }
    }    
  },
  watch: {
    selection: {
      handler() {
        this.$emit('update:selection', this.selection);
      }
    },
    searchValues: {
      deep: true,
      handler() {
        this.refresh();
      }
    },
    'pageOptions.page': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.refresh(newValue);
      }
    }
  },
  methods: {
    async refresh() {
      try {
        
        this.error = null;
        this.loading = true;
        const page = await findAllContatos(this.pageOptions.page, this.searchValues);

        this.itemsPerPage = page.elementsPerPage;
        this.totalElements = page.totalElements;
        this.elements = page.elements;
        this.pageOptions.page = page.currentPage;
        this.selection = [];

      } catch (err) {
        this.error = err;
        this.elements = [];
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível carregar a lista de contatos.`, {
            timeout: 6000
          })
        }        
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.pageOptions.page = 1;
    //this.refresh();
  }
}
</script>

<style scoped lang="scss">
.list-item {
  &::v-deep {
    .v-list-item__content {
      max-width: calc(100vw - 72px);
    }
  }
}
.list-item:not(:last-child) {
  border-bottom: 1px solid #dedede
}

</style>